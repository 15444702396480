export default
{
  language: {
    choose: 'Choose your language:',
  },
  header: {
    dear: 'Dear Harpist,',
    thanks: 'Thank you for your recent Salvi harp purchase! Your new Salvi harp is covered by a conventional warranty for a period of 3 years for lever harps and 5 years for pedal harps.',
    important: 'IMPORTANT: To activate the warranty, you need to register your new harp within 30 days of the purchase date. At the end of the registration procedure, you will receive a confirmation message by email. Please retain this message and print a copy to keep with the sales documents.',
    warranty: 'Warranty',
    registration: 'Registration of your new Salvi Harp',
    readTerms: 'Click here to read the warranty conditions',
  },
  serial: {
    number: 'Serial number of your harp',
    confirm: 'Confirm',
    find: 'Where do I see the serial number?',
    where: 'Beside the upper part of the column (metal plate or paper tag) – underneath the base of the harp.',
    family: 'Choose the family',
    bad_P: 'Check the Serial Number. If your harp is Pedal the serial number starts with P1 or P2 followed by 4 digits: P1****  P2****',
    not_found_P: 'We are not able to find your harp on our systems :( Check the Serial Number. If your harp is Pedal the serial number starts with P1 or P2 followed by 4 digits: P1****  P2****. If the problem persist send an email to ',
    bad_L: 'Check the Serial Number. If your harp is Lever the serial number starts with L3 or L4 followed by 4 digits: L3****  L4****',
    not_found_L: 'We are not able to find your harp on our systems :( Check the Serial Number. If your harp is Level the serial number starts with L3 or L4 followed by 4 digits: L3****  L4****. If the problem persist send an email to ',
    bad_DH: 'Check the Serial Number. If your instrument is a Delta the serial number starts with DH followed by 4 digits: DH****',
    not_found_DH: 'We are not able to find your harp on our systems :( Check the Serial Number. If your instrument is a Delta the serial number starts with DH followed by 4 digits: DH****. If the problem persist send an email to ',
  },
  registration: {
    harp: 'harp',
    finish: 'finish',
    serial: 'serial number',
    date: 'purchase date',
    dealer: 'select your dealer',
    customer: 'Customer',
    firstname: 'First Name',
    lastname: 'Last Name',
    address: 'Address',
    city: 'City',
    postcode: 'Postcode',
    province: 'Province',
    nations: 'Country and Region',
    phone: 'Phone',
    mail: 'E-mail address',
    other: 'OTHER',
    other_message: 'Specify an other dealer',
    terms: 'I accept terms and conditions of the Salvi Harps Warranty. For further details <a class="font-extrabold underline" href="/pdf/warranty-conditions.pdf" target="_blank">Click here</a>.',
    privacy: 'I accept the privacy policy. For further details <a class="font-extrabold underline" href="/pdf/Salvi_PrivacyPolicy-EN.pdf" target="_blank">Click here</a>.',
    tax: 'Tax Code',
    errors: 'Oh Snap! Something is wrong with the servers communication! Try again ... if the lazy servers does not work drop an email.',
    activation: 'Your Harp Warranty is succesfully activated!',
    'confirmation-mail': 'You\'ll received a confirmation mail to your address',
    'purchased-from': 'Purchased from:',
    'customer-details': 'Your Details:',
    'sale-date': 'Sale Date',
    'end-warranty': 'Warranty end date',
  },
  newsletter: {
    mail: 'Write here your mail..',
    subscribe: 'Subscribe to the Salvi Harps newsletter to receive interesting news and offers!',
  },
  input: {
    fillField: 'Fill in this field',
  },
};
