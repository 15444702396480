export default {
  en: [{
    id: 4, name: 'Afghanistan', code: 'af', code3: 'afg',
  },
  {
    id: 8, name: 'Albania', code: 'al', code3: 'alb',
  },
  {
    id: 12, name: 'Algeria', code: 'dz', code3: 'dza',
  },
  {
    id: 20, name: 'Andorra', code: 'ad', code3: 'and',
  },
  {
    id: 24, name: 'Angola', code: 'ao', code3: 'ago',
  },
  {
    id: 28, name: 'Antigua and Barbuda', code: 'ag', code3: 'atg',
  },
  {
    id: 32, name: 'Argentina', code: 'ar', code3: 'arg',
  },
  {
    id: 51, name: 'Armenia', code: 'am', code3: 'arm',
  },
  {
    id: 36, name: 'Australia', code: 'au', code3: 'aus',
  },
  {
    id: 40, name: 'Austria', code: 'at', code3: 'aut',
  },
  {
    id: 31, name: 'Azerbaijan', code: 'az', code3: 'aze',
  },
  {
    id: 44, name: 'Bahamas', code: 'bs', code3: 'bhs',
  },
  {
    id: 48, name: 'Bahrain', code: 'bh', code3: 'bhr',
  },
  {
    id: 50, name: 'Bangladesh', code: 'bd', code3: 'bgd',
  },
  {
    id: 52, name: 'Barbados', code: 'bb', code3: 'brb',
  },
  {
    id: 112, name: 'Belarus', code: 'by', code3: 'blr',
  },
  {
    id: 56, name: 'Belgium', code: 'be', code3: 'bel',
  },
  {
    id: 84, name: 'Belize', code: 'bz', code3: 'blz',
  },
  {
    id: 204, name: 'Benin', code: 'bj', code3: 'ben',
  },
  {
    id: 64, name: 'Bhutan', code: 'bt', code3: 'btn',
  },
  {
    id: 68, name: 'Bolivia (Plurinational State of)', code: 'bo', code3: 'bol',
  },
  {
    id: 70, name: 'Bosnia and Herzegovina', code: 'ba', code3: 'bih',
  },
  {
    id: 72, name: 'Botswana', code: 'bw', code3: 'bwa',
  },
  {
    id: 76, name: 'Brazil', code: 'br', code3: 'bra',
  },
  {
    id: 96, name: 'Brunei Darussalam', code: 'bn', code3: 'brn',
  },
  {
    id: 100, name: 'Bulgaria', code: 'bg', code3: 'bgr',
  },
  {
    id: 854, name: 'Burkina Faso', code: 'bf', code3: 'bfa',
  },
  {
    id: 108, name: 'Burundi', code: 'bi', code3: 'bdi',
  },
  {
    id: 132, name: 'Cabo Verde', code: 'cv', code3: 'cpv',
  },
  {
    id: 116, name: 'Cambodia', code: 'kh', code3: 'khm',
  },
  {
    id: 120, name: 'Cameroon', code: 'cm', code3: 'cmr',
  },
  {
    id: 124, name: 'Canada', code: 'ca', code3: 'can',
  },
  {
    id: 140, name: 'Central African Republic', code: 'cf', code3: 'caf',
  },
  {
    id: 148, name: 'Chad', code: 'td', code3: 'tcd',
  },
  {
    id: 152, name: 'Chile', code: 'cl', code3: 'chl',
  },
  {
    id: 156, name: 'China', code: 'cn', code3: 'chn',
  },
  {
    id: 170, name: 'Colombia', code: 'co', code3: 'col',
  },
  {
    id: 174, name: 'Comoros', code: 'km', code3: 'com',
  },
  {
    id: 178, name: 'Congo', code: 'cg', code3: 'cog',
  },
  {
    id: 180, name: 'Congo, Democratic Republic of the', code: 'cd', code3: 'cod',
  },
  {
    id: 188, name: 'Costa Rica', code: 'cr', code3: 'cri',
  },
  {
    id: 384, name: "Côte d'Ivoire", code: 'ci', code3: 'civ',
  },
  {
    id: 191, name: 'Croatia', code: 'hr', code3: 'hrv',
  },
  {
    id: 192, name: 'Cuba', code: 'cu', code3: 'cub',
  },
  {
    id: 196, name: 'Cyprus', code: 'cy', code3: 'cyp',
  },
  {
    id: 203, name: 'Czechia', code: 'cz', code3: 'cze',
  },
  {
    id: 208, name: 'Denmark', code: 'dk', code3: 'dnk',
  },
  {
    id: 262, name: 'Djibouti', code: 'dj', code3: 'dji',
  },
  {
    id: 212, name: 'Dominica', code: 'dm', code3: 'dma',
  },
  {
    id: 214, name: 'Dominican Republic', code: 'do', code3: 'dom',
  },
  {
    id: 218, name: 'Ecuador', code: 'ec', code3: 'ecu',
  },
  {
    id: 818, name: 'Egypt', code: 'eg', code3: 'egy',
  },
  {
    id: 222, name: 'El Salvador', code: 'sv', code3: 'slv',
  },
  {
    id: 226, name: 'Equatorial Guinea', code: 'gq', code3: 'gnq',
  },
  {
    id: 232, name: 'Eritrea', code: 'er', code3: 'eri',
  },
  {
    id: 233, name: 'Estonia', code: 'ee', code3: 'est',
  },
  {
    id: 748, name: 'Eswatini', code: 'sz', code3: 'swz',
  },
  {
    id: 231, name: 'Ethiopia', code: 'et', code3: 'eth',
  },
  {
    id: 242, name: 'Fiji', code: 'fj', code3: 'fji',
  },
  {
    id: 246, name: 'Finland', code: 'fi', code3: 'fin',
  },
  {
    id: 250, name: 'France', code: 'fr', code3: 'fra',
  },
  {
    id: 266, name: 'Gabon', code: 'ga', code3: 'gab',
  },
  {
    id: 270, name: 'Gambia', code: 'gm', code3: 'gmb',
  },
  {
    id: 268, name: 'Georgia', code: 'ge', code3: 'geo',
  },
  {
    id: 276, name: 'Germany', code: 'de', code3: 'deu',
  },
  {
    id: 288, name: 'Ghana', code: 'gh', code3: 'gha',
  },
  {
    id: 300, name: 'Greece', code: 'gr', code3: 'grc',
  },
  {
    id: 308, name: 'Grenada', code: 'gd', code3: 'grd',
  },
  {
    id: 320, name: 'Guatemala', code: 'gt', code3: 'gtm',
  },
  {
    id: 324, name: 'Guinea', code: 'gn', code3: 'gin',
  },
  {
    id: 624, name: 'Guinea-Bissau', code: 'gw', code3: 'gnb',
  },
  {
    id: 328, name: 'Guyana', code: 'gy', code3: 'guy',
  },
  {
    id: 332, name: 'Haiti', code: 'ht', code3: 'hti',
  },
  {
    id: 340, name: 'Honduras', code: 'hn', code3: 'hnd',
  },
  {
    id: 348, name: 'Hungary', code: 'hu', code3: 'hun',
  },
  {
    id: 352, name: 'Iceland', code: 'is', code3: 'isl',
  },
  {
    id: 356, name: 'India', code: 'in', code3: 'ind',
  },
  {
    id: 360, name: 'Indonesia', code: 'id', code3: 'idn',
  },
  {
    id: 364, name: 'Iran (Islamic Republic of)', code: 'ir', code3: 'irn',
  },
  {
    id: 368, name: 'Iraq', code: 'iq', code3: 'irq',
  },
  {
    id: 372, name: 'Ireland', code: 'ie', code3: 'irl',
  },
  {
    id: 376, name: 'Israel', code: 'il', code3: 'isr',
  },
  {
    id: 380, name: 'Italy', code: 'it', code3: 'ita',
  },
  {
    id: 388, name: 'Jamaica', code: 'jm', code3: 'jam',
  },
  {
    id: 392, name: 'Japan', code: 'jp', code3: 'jpn',
  },
  {
    id: 400, name: 'Jordan', code: 'jo', code3: 'jor',
  },
  {
    id: 398, name: 'Kazakhstan', code: 'kz', code3: 'kaz',
  },
  {
    id: 404, name: 'Kenya', code: 'ke', code3: 'ken',
  },
  {
    id: 296, name: 'Kiribati', code: 'ki', code3: 'kir',
  },
  {
    id: 408, name: "Korea (Democratic People's Republic of)", code: 'kp', code3: 'prk',
  },
  {
    id: 410, name: 'Korea, Republic of', code: 'kr', code3: 'kor',
  },
  {
    id: 414, name: 'Kuwait', code: 'kw', code3: 'kwt',
  },
  {
    id: 417, name: 'Kyrgyzstan', code: 'kg', code3: 'kgz',
  },
  {
    id: 418, name: "Lao People's Democratic Republic", code: 'la', code3: 'lao',
  },
  {
    id: 428, name: 'Latvia', code: 'lv', code3: 'lva',
  },
  {
    id: 422, name: 'Lebanon', code: 'lb', code3: 'lbn',
  },
  {
    id: 426, name: 'Lesotho', code: 'ls', code3: 'lso',
  },
  {
    id: 430, name: 'Liberia', code: 'lr', code3: 'lbr',
  },
  {
    id: 434, name: 'Libya', code: 'ly', code3: 'lby',
  },
  {
    id: 438, name: 'Liechtenstein', code: 'li', code3: 'lie',
  },
  {
    id: 440, name: 'Lithuania', code: 'lt', code3: 'ltu',
  },
  {
    id: 442, name: 'Luxembourg', code: 'lu', code3: 'lux',
  },
  {
    id: 450, name: 'Madagascar', code: 'mg', code3: 'mdg',
  },
  {
    id: 454, name: 'Malawi', code: 'mw', code3: 'mwi',
  },
  {
    id: 458, name: 'Malaysia', code: 'my', code3: 'mys',
  },
  {
    id: 462, name: 'Maldives', code: 'mv', code3: 'mdv',
  },
  {
    id: 466, name: 'Mali', code: 'ml', code3: 'mli',
  },
  {
    id: 470, name: 'Malta', code: 'mt', code3: 'mlt',
  },
  {
    id: 584, name: 'Marshall Islands', code: 'mh', code3: 'mhl',
  },
  {
    id: 478, name: 'Mauritania', code: 'mr', code3: 'mrt',
  },
  {
    id: 480, name: 'Mauritius', code: 'mu', code3: 'mus',
  },
  {
    id: 484, name: 'Mexico', code: 'mx', code3: 'mex',
  },
  {
    id: 583, name: 'Micronesia (Federated States of)', code: 'fm', code3: 'fsm',
  },
  {
    id: 498, name: 'Moldova, Republic of', code: 'md', code3: 'mda',
  },
  {
    id: 492, name: 'Monaco', code: 'mc', code3: 'mco',
  },
  {
    id: 496, name: 'Mongolia', code: 'mn', code3: 'mng',
  },
  {
    id: 499, name: 'Montenegro', code: 'me', code3: 'mne',
  },
  {
    id: 504, name: 'Morocco', code: 'ma', code3: 'mar',
  },
  {
    id: 508, name: 'Mozambique', code: 'mz', code3: 'moz',
  },
  {
    id: 104, name: 'Myanmar', code: 'mm', code3: 'mmr',
  },
  {
    id: 516, name: 'Namibia', code: 'na', code3: 'nam',
  },
  {
    id: 520, name: 'Nauru', code: 'nr', code3: 'nru',
  },
  {
    id: 524, name: 'Nepal', code: 'np', code3: 'npl',
  },
  {
    id: 528, name: 'Netherlands', code: 'nl', code3: 'nld',
  },
  {
    id: 554, name: 'New Zealand', code: 'nz', code3: 'nzl',
  },
  {
    id: 558, name: 'Nicaragua', code: 'ni', code3: 'nic',
  },
  {
    id: 562, name: 'Niger', code: 'ne', code3: 'ner',
  },
  {
    id: 566, name: 'Nigeria', code: 'ng', code3: 'nga',
  },
  {
    id: 807, name: 'North Macedonia', code: 'mk', code3: 'mkd',
  },
  {
    id: 578, name: 'Norway', code: 'no', code3: 'nor',
  },
  {
    id: 512, name: 'Oman', code: 'om', code3: 'omn',
  },
  {
    id: 586, name: 'Pakistan', code: 'pk', code3: 'pak',
  },
  {
    id: 585, name: 'Palau', code: 'pw', code3: 'plw',
  },
  {
    id: 591, name: 'Panama', code: 'pa', code3: 'pan',
  },
  {
    id: 598, name: 'Papua New Guinea', code: 'pg', code3: 'png',
  },
  {
    id: 600, name: 'Paraguay', code: 'py', code3: 'pry',
  },
  {
    id: 604, name: 'Peru', code: 'pe', code3: 'per',
  },
  {
    id: 608, name: 'Philippines', code: 'ph', code3: 'phl',
  },
  {
    id: 616, name: 'Poland', code: 'pl', code3: 'pol',
  },
  {
    id: 620, name: 'Portugal', code: 'pt', code3: 'prt',
  },
  {
    id: 634, name: 'Qatar', code: 'qa', code3: 'qat',
  },
  {
    id: 642, name: 'Romania', code: 'ro', code3: 'rou',
  },
  {
    id: 643, name: 'Russian Federation', code: 'ru', code3: 'rus',
  },
  {
    id: 646, name: 'Rwanda', code: 'rw', code3: 'rwa',
  },
  {
    id: 659, name: 'Saint Kitts and Nevis', code: 'kn', code3: 'kna',
  },
  {
    id: 662, name: 'Saint Lucia', code: 'lc', code3: 'lca',
  },
  {
    id: 670, name: 'Saint Vincent and the Grenadines', code: 'vc', code3: 'vct',
  },
  {
    id: 882, name: 'Samoa', code: 'ws', code3: 'wsm',
  },
  {
    id: 674, name: 'San Marino', code: 'sm', code3: 'smr',
  },
  {
    id: 678, name: 'Sao Tome and Principe', code: 'st', code3: 'stp',
  },
  {
    id: 682, name: 'Saudi Arabia', code: 'sa', code3: 'sau',
  },
  {
    id: 686, name: 'Senegal', code: 'sn', code3: 'sen',
  },
  {
    id: 688, name: 'Serbia', code: 'rs', code3: 'srb',
  },
  {
    id: 690, name: 'Seychelles', code: 'sc', code3: 'syc',
  },
  {
    id: 694, name: 'Sierra Leone', code: 'sl', code3: 'sle',
  },
  {
    id: 702, name: 'Singapore', code: 'sg', code3: 'sgp',
  },
  {
    id: 703, name: 'Slovakia', code: 'sk', code3: 'svk',
  },
  {
    id: 705, name: 'Slovenia', code: 'si', code3: 'svn',
  },
  {
    id: 90, name: 'Solomon Islands', code: 'sb', code3: 'slb',
  },
  {
    id: 706, name: 'Somalia', code: 'so', code3: 'som',
  },
  {
    id: 710, name: 'South Africa', code: 'za', code3: 'zaf',
  },
  {
    id: 728, name: 'South Sudan', code: 'ss', code3: 'ssd',
  },
  {
    id: 724, name: 'Spain', code: 'es', code3: 'esp',
  },
  {
    id: 144, name: 'Sri Lanka', code: 'lk', code3: 'lka',
  },
  {
    id: 729, name: 'Sudan', code: 'sd', code3: 'sdn',
  },
  {
    id: 740, name: 'Suriname', code: 'sr', code3: 'sur',
  },
  {
    id: 752, name: 'Sweden', code: 'se', code3: 'swe',
  },
  {
    id: 756, name: 'Switzerland', code: 'ch', code3: 'che',
  },
  {
    id: 760, name: 'Syrian Arab Republic', code: 'sy', code3: 'syr',
  },
  {
    id: 158, name: 'Taiwan', code: 'tw', code3: 'twn',
  },
  {
    id: 762, name: 'Tajikistan', code: 'tj', code3: 'tjk',
  },
  {
    id: 834, name: 'Tanzania, United Republic of', code: 'tz', code3: 'tza',
  },
  {
    id: 764, name: 'Thailand', code: 'th', code3: 'tha',
  },
  {
    id: 626, name: 'Timor-Leste', code: 'tl', code3: 'tls',
  },
  {
    id: 768, name: 'Togo', code: 'tg', code3: 'tgo',
  },
  {
    id: 776, name: 'Tonga', code: 'to', code3: 'ton',
  },
  {
    id: 780, name: 'Trinidad and Tobago', code: 'tt', code3: 'tto',
  },
  {
    id: 788, name: 'Tunisia', code: 'tn', code3: 'tun',
  },
  {
    id: 792, name: 'Turkey', code: 'tr', code3: 'tur',
  },
  {
    id: 795, name: 'Turkmenistan', code: 'tm', code3: 'tkm',
  },
  {
    id: 798, name: 'Tuvalu', code: 'tv', code3: 'tuv',
  },
  {
    id: 800, name: 'Uganda', code: 'ug', code3: 'uga',
  },
  {
    id: 804, name: 'Ukraine', code: 'ua', code3: 'ukr',
  },
  {
    id: 784, name: 'United Arab Emirates', code: 'ae', code3: 'are',
  },
  {
    id: 826, name: 'United Kingdom of Great Britain and Northern Ireland', code: 'gb', code3: 'gbr',
  },
  {
    id: 840, name: 'United States of America', code: 'us', code3: 'usa',
  },
  {
    id: 858, name: 'Uruguay', code: 'uy', code3: 'ury',
  },
  {
    id: 860, name: 'Uzbekistan', code: 'uz', code3: 'uzb',
  },
  {
    id: 548, name: 'Vanuatu', code: 'vu', code3: 'vut',
  },
  {
    id: 862, name: 'Venezuela (Bolivarian Republic of)', code: 've', code3: 'ven',
  },
  {
    id: 704, name: 'Viet Nam', code: 'vn', code3: 'vnm',
  },
  {
    id: 887, name: 'Yemen', code: 'ye', code3: 'yem',
  },
  {
    id: 894, name: 'Zambia', code: 'zm', code3: 'zmb',
  },
  {
    id: 716, name: 'Zimbabwe', code: 'zw', code3: 'zwe',
  }],

  de: [{
    id: 4, name: 'Afghanistan', code: 'af', code3: 'afg',
  },
  {
    id: 818, name: 'Ägypten', code: 'eg', code3: 'egy',
  },
  {
    id: 8, name: 'Albanien', code: 'al', code3: 'alb',
  },
  {
    id: 12, name: 'Algerien', code: 'dz', code3: 'dza',
  },
  {
    id: 20, name: 'Andorra', code: 'ad', code3: 'and',
  },
  {
    id: 24, name: 'Angola', code: 'ao', code3: 'ago',
  },
  {
    id: 28, name: 'Antigua und Barbuda', code: 'ag', code3: 'atg',
  },
  {
    id: 226, name: 'Äquatorialguinea', code: 'gq', code3: 'gnq',
  },
  {
    id: 32, name: 'Argentinien', code: 'ar', code3: 'arg',
  },
  {
    id: 51, name: 'Armenien', code: 'am', code3: 'arm',
  },
  {
    id: 31, name: 'Aserbaidschan', code: 'az', code3: 'aze',
  },
  {
    id: 231, name: 'Äthiopien', code: 'et', code3: 'eth',
  },
  {
    id: 36, name: 'Australien', code: 'au', code3: 'aus',
  },
  {
    id: 44, name: 'Bahamas', code: 'bs', code3: 'bhs',
  },
  {
    id: 48, name: 'Bahrain', code: 'bh', code3: 'bhr',
  },
  {
    id: 50, name: 'Bangladesch', code: 'bd', code3: 'bgd',
  },
  {
    id: 52, name: 'Barbados', code: 'bb', code3: 'brb',
  },
  {
    id: 112, name: 'Belarus', code: 'by', code3: 'blr',
  },
  {
    id: 56, name: 'Belgien', code: 'be', code3: 'bel',
  },
  {
    id: 84, name: 'Belize', code: 'bz', code3: 'blz',
  },
  {
    id: 204, name: 'Benin', code: 'bj', code3: 'ben',
  },
  {
    id: 64, name: 'Bhutan', code: 'bt', code3: 'btn',
  },
  {
    id: 68, name: 'Bolivien', code: 'bo', code3: 'bol',
  },
  {
    id: 70, name: 'Bosnien und Herzegowina', code: 'ba', code3: 'bih',
  },
  {
    id: 72, name: 'Botswana', code: 'bw', code3: 'bwa',
  },
  {
    id: 76, name: 'Brasilien', code: 'br', code3: 'bra',
  },
  {
    id: 96, name: 'Brunei', code: 'bn', code3: 'brn',
  },
  {
    id: 100, name: 'Bulgarien', code: 'bg', code3: 'bgr',
  },
  {
    id: 854, name: 'Burkina Faso', code: 'bf', code3: 'bfa',
  },
  {
    id: 108, name: 'Burundi', code: 'bi', code3: 'bdi',
  },
  {
    id: 152, name: 'Chile', code: 'cl', code3: 'chl',
  },
  {
    id: 156, name: 'Volksrepublik China', code: 'cn', code3: 'chn',
  },
  {
    id: 188, name: 'Costa Rica', code: 'cr', code3: 'cri',
  },
  {
    id: 208, name: 'Dänemark', code: 'dk', code3: 'dnk',
  },
  {
    id: 276, name: 'Deutschland', code: 'de', code3: 'deu',
  },
  {
    id: 212, name: 'Dominica', code: 'dm', code3: 'dma',
  },
  {
    id: 214, name: 'Dominikanische Republik', code: 'do', code3: 'dom',
  },
  {
    id: 262, name: 'Dschibuti', code: 'dj', code3: 'dji',
  },
  {
    id: 218, name: 'Ecuador', code: 'ec', code3: 'ecu',
  },
  {
    id: 384, name: 'Elfenbeinküste', code: 'ci', code3: 'civ',
  },
  {
    id: 222, name: 'El Salvador', code: 'sv', code3: 'slv',
  },
  {
    id: 232, name: 'Eritrea', code: 'er', code3: 'eri',
  },
  {
    id: 233, name: 'Estland', code: 'ee', code3: 'est',
  },
  {
    id: 748, name: 'Eswatini', code: 'sz', code3: 'swz',
  },
  {
    id: 242, name: 'Fidschi', code: 'fj', code3: 'fji',
  },
  {
    id: 246, name: 'Finnland', code: 'fi', code3: 'fin',
  },
  {
    id: 250, name: 'Frankreich', code: 'fr', code3: 'fra',
  },
  {
    id: 266, name: 'Gabun', code: 'ga', code3: 'gab',
  },
  {
    id: 270, name: 'Gambia', code: 'gm', code3: 'gmb',
  },
  {
    id: 268, name: 'Georgien', code: 'ge', code3: 'geo',
  },
  {
    id: 288, name: 'Ghana', code: 'gh', code3: 'gha',
  },
  {
    id: 308, name: 'Grenada', code: 'gd', code3: 'grd',
  },
  {
    id: 300, name: 'Griechenland', code: 'gr', code3: 'grc',
  },
  {
    id: 320, name: 'Guatemala', code: 'gt', code3: 'gtm',
  },
  {
    id: 324, name: 'Guinea', code: 'gn', code3: 'gin',
  },
  {
    id: 624, name: 'Guinea-Bissau', code: 'gw', code3: 'gnb',
  },
  {
    id: 328, name: 'Guyana', code: 'gy', code3: 'guy',
  },
  {
    id: 332, name: 'Haiti', code: 'ht', code3: 'hti',
  },
  {
    id: 340, name: 'Honduras', code: 'hn', code3: 'hnd',
  },
  {
    id: 356, name: 'Indien', code: 'in', code3: 'ind',
  },
  {
    id: 360, name: 'Indonesien', code: 'id', code3: 'idn',
  },
  {
    id: 368, name: 'Irak', code: 'iq', code3: 'irq',
  },
  {
    id: 364, name: 'Iran', code: 'ir', code3: 'irn',
  },
  {
    id: 372, name: 'Irland', code: 'ie', code3: 'irl',
  },
  {
    id: 352, name: 'Island', code: 'is', code3: 'isl',
  },
  {
    id: 376, name: 'Israel', code: 'il', code3: 'isr',
  },
  {
    id: 380, name: 'Italien', code: 'it', code3: 'ita',
  },
  {
    id: 388, name: 'Jamaika', code: 'jm', code3: 'jam',
  },
  {
    id: 392, name: 'Japan', code: 'jp', code3: 'jpn',
  },
  {
    id: 887, name: 'Jemen', code: 'ye', code3: 'yem',
  },
  {
    id: 400, name: 'Jordanien', code: 'jo', code3: 'jor',
  },
  {
    id: 116, name: 'Kambodscha', code: 'kh', code3: 'khm',
  },
  {
    id: 120, name: 'Kamerun', code: 'cm', code3: 'cmr',
  },
  {
    id: 124, name: 'Kanada', code: 'ca', code3: 'can',
  },
  {
    id: 132, name: 'Kap Verde', code: 'cv', code3: 'cpv',
  },
  {
    id: 398, name: 'Kasachstan', code: 'kz', code3: 'kaz',
  },
  {
    id: 634, name: 'Katar', code: 'qa', code3: 'qat',
  },
  {
    id: 404, name: 'Kenia', code: 'ke', code3: 'ken',
  },
  {
    id: 417, name: 'Kirgisistan', code: 'kg', code3: 'kgz',
  },
  {
    id: 296, name: 'Kiribati', code: 'ki', code3: 'kir',
  },
  {
    id: 170, name: 'Kolumbien', code: 'co', code3: 'col',
  },
  {
    id: 174, name: 'Komoren', code: 'km', code3: 'com',
  },
  {
    id: 180, name: 'Kongo, Demokratische Republik', code: 'cd', code3: 'cod',
  },
  {
    id: 178, name: 'Kongo, Republik', code: 'cg', code3: 'cog',
  },
  {
    id: 408, name: 'Korea, Nord', code: 'kp', code3: 'prk',
  },
  {
    id: 410, name: 'Korea, Süd', code: 'kr', code3: 'kor',
  },
  {
    id: 191, name: 'Kroatien', code: 'hr', code3: 'hrv',
  },
  {
    id: 192, name: 'Kuba', code: 'cu', code3: 'cub',
  },
  {
    id: 414, name: 'Kuwait', code: 'kw', code3: 'kwt',
  },
  {
    id: 418, name: 'Laos', code: 'la', code3: 'lao',
  },
  {
    id: 426, name: 'Lesotho', code: 'ls', code3: 'lso',
  },
  {
    id: 428, name: 'Lettland', code: 'lv', code3: 'lva',
  },
  {
    id: 422, name: 'Libanon', code: 'lb', code3: 'lbn',
  },
  {
    id: 430, name: 'Liberia', code: 'lr', code3: 'lbr',
  },
  {
    id: 434, name: 'Libyen', code: 'ly', code3: 'lby',
  },
  {
    id: 438, name: 'Liechtenstein', code: 'li', code3: 'lie',
  },
  {
    id: 440, name: 'Litauen', code: 'lt', code3: 'ltu',
  },
  {
    id: 442, name: 'Luxemburg', code: 'lu', code3: 'lux',
  },
  {
    id: 450, name: 'Madagaskar', code: 'mg', code3: 'mdg',
  },
  {
    id: 454, name: 'Malawi', code: 'mw', code3: 'mwi',
  },
  {
    id: 458, name: 'Malaysia', code: 'my', code3: 'mys',
  },
  {
    id: 462, name: 'Malediven', code: 'mv', code3: 'mdv',
  },
  {
    id: 466, name: 'Mali', code: 'ml', code3: 'mli',
  },
  {
    id: 470, name: 'Malta', code: 'mt', code3: 'mlt',
  },
  {
    id: 504, name: 'Marokko', code: 'ma', code3: 'mar',
  },
  {
    id: 584, name: 'Marshallinseln', code: 'mh', code3: 'mhl',
  },
  {
    id: 478, name: 'Mauretanien', code: 'mr', code3: 'mrt',
  },
  {
    id: 480, name: 'Mauritius', code: 'mu', code3: 'mus',
  },
  {
    id: 484, name: 'Mexiko', code: 'mx', code3: 'mex',
  },
  {
    id: 583, name: 'Mikronesien', code: 'fm', code3: 'fsm',
  },
  {
    id: 498, name: 'Moldau', code: 'md', code3: 'mda',
  },
  {
    id: 492, name: 'Monaco', code: 'mc', code3: 'mco',
  },
  {
    id: 496, name: 'Mongolei', code: 'mn', code3: 'mng',
  },
  {
    id: 499, name: 'Montenegro', code: 'me', code3: 'mne',
  },
  {
    id: 508, name: 'Mosambik', code: 'mz', code3: 'moz',
  },
  {
    id: 104, name: 'Myanmar', code: 'mm', code3: 'mmr',
  },
  {
    id: 516, name: 'Namibia', code: 'na', code3: 'nam',
  },
  {
    id: 520, name: 'Nauru', code: 'nr', code3: 'nru',
  },
  {
    id: 524, name: 'Nepal', code: 'np', code3: 'npl',
  },
  {
    id: 554, name: 'Neuseeland', code: 'nz', code3: 'nzl',
  },
  {
    id: 558, name: 'Nicaragua', code: 'ni', code3: 'nic',
  },
  {
    id: 528, name: 'Niederlande', code: 'nl', code3: 'nld',
  },
  {
    id: 562, name: 'Niger', code: 'ne', code3: 'ner',
  },
  {
    id: 566, name: 'Nigeria', code: 'ng', code3: 'nga',
  },
  {
    id: 807, name: 'Nordmazedonien', code: 'mk', code3: 'mkd',
  },
  {
    id: 578, name: 'Norwegen', code: 'no', code3: 'nor',
  },
  {
    id: 512, name: 'Oman', code: 'om', code3: 'omn',
  },
  {
    id: 40, name: 'Österreich', code: 'at', code3: 'aut',
  },
  {
    id: 626, name: 'Osttimor', code: 'tl', code3: 'tls',
  },
  {
    id: 586, name: 'Pakistan', code: 'pk', code3: 'pak',
  },
  {
    id: 585, name: 'Palau', code: 'pw', code3: 'plw',
  },
  {
    id: 591, name: 'Panama', code: 'pa', code3: 'pan',
  },
  {
    id: 598, name: 'Papua-Neuguinea', code: 'pg', code3: 'png',
  },
  {
    id: 600, name: 'Paraguay', code: 'py', code3: 'pry',
  },
  {
    id: 604, name: 'Peru', code: 'pe', code3: 'per',
  },
  {
    id: 608, name: 'Philippinen', code: 'ph', code3: 'phl',
  },
  {
    id: 616, name: 'Polen', code: 'pl', code3: 'pol',
  },
  {
    id: 620, name: 'Portugal', code: 'pt', code3: 'prt',
  },
  {
    id: 646, name: 'Ruanda', code: 'rw', code3: 'rwa',
  },
  {
    id: 642, name: 'Rumänien', code: 'ro', code3: 'rou',
  },
  {
    id: 643, name: 'Russland', code: 'ru', code3: 'rus',
  },
  {
    id: 90, name: 'Salomonen', code: 'sb', code3: 'slb',
  },
  {
    id: 894, name: 'Sambia', code: 'zm', code3: 'zmb',
  },
  {
    id: 882, name: 'Samoa', code: 'ws', code3: 'wsm',
  },
  {
    id: 674, name: 'San Marino', code: 'sm', code3: 'smr',
  },
  {
    id: 678, name: 'São Tomé und Príncipe', code: 'st', code3: 'stp',
  },
  {
    id: 682, name: 'Saudi-Arabien', code: 'sa', code3: 'sau',
  },
  {
    id: 752, name: 'Schweden', code: 'se', code3: 'swe',
  },
  {
    id: 756, name: 'Schweiz', code: 'ch', code3: 'che',
  },
  {
    id: 686, name: 'Senegal', code: 'sn', code3: 'sen',
  },
  {
    id: 688, name: 'Serbien', code: 'rs', code3: 'srb',
  },
  {
    id: 690, name: 'Seychellen', code: 'sc', code3: 'syc',
  },
  {
    id: 694, name: 'Sierra Leone', code: 'sl', code3: 'sle',
  },
  {
    id: 716, name: 'Simbabwe', code: 'zw', code3: 'zwe',
  },
  {
    id: 702, name: 'Singapur', code: 'sg', code3: 'sgp',
  },
  {
    id: 703, name: 'Slowakei', code: 'sk', code3: 'svk',
  },
  {
    id: 705, name: 'Slowenien', code: 'si', code3: 'svn',
  },
  {
    id: 706, name: 'Somalia', code: 'so', code3: 'som',
  },
  {
    id: 724, name: 'Spanien', code: 'es', code3: 'esp',
  },
  {
    id: 144, name: 'Sri Lanka', code: 'lk', code3: 'lka',
  },
  {
    id: 659, name: 'St. Kitts und Nevis', code: 'kn', code3: 'kna',
  },
  {
    id: 662, name: 'St. Lucia', code: 'lc', code3: 'lca',
  },
  {
    id: 670, name: 'St. Vincent und die Grenadinen', code: 'vc', code3: 'vct',
  },
  {
    id: 710, name: 'Südafrika', code: 'za', code3: 'zaf',
  },
  {
    id: 729, name: 'Sudan', code: 'sd', code3: 'sdn',
  },
  {
    id: 728, name: 'Südsudan', code: 'ss', code3: 'ssd',
  },
  {
    id: 740, name: 'Suriname', code: 'sr', code3: 'sur',
  },
  {
    id: 760, name: 'Syrien', code: 'sy', code3: 'syr',
  },
  {
    id: 762, name: 'Tadschikistan', code: 'tj', code3: 'tjk',
  },
  {
    id: 834, name: 'Tansania', code: 'tz', code3: 'tza',
  },
  {
    id: 158, name: 'Taiwan', code: 'tw', code3: 'twn',
  },
  {
    id: 764, name: 'Thailand', code: 'th', code3: 'tha',
  },
  {
    id: 768, name: 'Togo', code: 'tg', code3: 'tgo',
  },
  {
    id: 776, name: 'Tonga', code: 'to', code3: 'ton',
  },
  {
    id: 780, name: 'Trinidad und Tobago', code: 'tt', code3: 'tto',
  },
  {
    id: 148, name: 'Tschad', code: 'td', code3: 'tcd',
  },
  {
    id: 203, name: 'Tschechien', code: 'cz', code3: 'cze',
  },
  {
    id: 788, name: 'Tunesien', code: 'tn', code3: 'tun',
  },
  {
    id: 792, name: 'Türkei', code: 'tr', code3: 'tur',
  },
  {
    id: 795, name: 'Turkmenistan', code: 'tm', code3: 'tkm',
  },
  {
    id: 798, name: 'Tuvalu', code: 'tv', code3: 'tuv',
  },
  {
    id: 800, name: 'Uganda', code: 'ug', code3: 'uga',
  },
  {
    id: 804, name: 'Ukraine', code: 'ua', code3: 'ukr',
  },
  {
    id: 348, name: 'Ungarn', code: 'hu', code3: 'hun',
  },
  {
    id: 858, name: 'Uruguay', code: 'uy', code3: 'ury',
  },
  {
    id: 860, name: 'Usbekistan', code: 'uz', code3: 'uzb',
  },
  {
    id: 548, name: 'Vanuatu', code: 'vu', code3: 'vut',
  },
  {
    id: 862, name: 'Venezuela', code: 've', code3: 'ven',
  },
  {
    id: 784, name: 'Vereinigte Arabische Emirate', code: 'ae', code3: 'are',
  },
  {
    id: 840, name: 'Vereinigte Staaten', code: 'us', code3: 'usa',
  },
  {
    id: 826, name: 'Vereinigtes Königreich', code: 'gb', code3: 'gbr',
  },
  {
    id: 704, name: 'Vietnam', code: 'vn', code3: 'vnm',
  },
  {
    id: 140, name: 'Zentral­afrikanische Republik', code: 'cf', code3: 'caf',
  },
  {
    id: 196, name: 'Zypern', code: 'cy', code3: 'cyp',
  }],

  it: [{
    id: 4, name: 'Afghanistan', code: 'af', code3: 'afg',
  },
  {
    id: 8, name: 'Albania', code: 'al', code3: 'alb',
  },
  {
    id: 12, name: 'Algeria', code: 'dz', code3: 'dza',
  },
  {
    id: 20, name: 'Andorra', code: 'ad', code3: 'and',
  },
  {
    id: 24, name: 'Angola', code: 'ao', code3: 'ago',
  },
  {
    id: 28, name: 'Antigua e Barbuda', code: 'ag', code3: 'atg',
  },
  {
    id: 682, name: 'Arabia Saudita', code: 'sa', code3: 'sau',
  },
  {
    id: 32, name: 'Argentina', code: 'ar', code3: 'arg',
  },
  {
    id: 51, name: 'Armenia', code: 'am', code3: 'arm',
  },
  {
    id: 36, name: 'Australia', code: 'au', code3: 'aus',
  },
  {
    id: 40, name: 'Austria', code: 'at', code3: 'aut',
  },
  {
    id: 31, name: 'Azerbaigian', code: 'az', code3: 'aze',
  },
  {
    id: 44, name: 'Bahamas', code: 'bs', code3: 'bhs',
  },
  {
    id: 48, name: 'Bahrein', code: 'bh', code3: 'bhr',
  },
  {
    id: 50, name: 'Bangladesh', code: 'bd', code3: 'bgd',
  },
  {
    id: 52, name: 'Barbados', code: 'bb', code3: 'brb',
  },
  {
    id: 56, name: 'Belgio', code: 'be', code3: 'bel',
  },
  {
    id: 84, name: 'Belize', code: 'bz', code3: 'blz',
  },
  {
    id: 204, name: 'Benin', code: 'bj', code3: 'ben',
  },
  {
    id: 64, name: 'Bhutan', code: 'bt', code3: 'btn',
  },
  {
    id: 112, name: 'Bielorussia', code: 'by', code3: 'blr',
  },
  {
    id: 104, name: 'Birmania', code: 'mm', code3: 'mmr',
  },
  {
    id: 68, name: 'Bolivia', code: 'bo', code3: 'bol',
  },
  {
    id: 70, name: 'Bosnia ed Erzegovina', code: 'ba', code3: 'bih',
  },
  {
    id: 72, name: 'Botswana', code: 'bw', code3: 'bwa',
  },
  {
    id: 76, name: 'Brasile', code: 'br', code3: 'bra',
  },
  {
    id: 96, name: 'Brunei', code: 'bn', code3: 'brn',
  },
  {
    id: 100, name: 'Bulgaria', code: 'bg', code3: 'bgr',
  },
  {
    id: 854, name: 'Burkina Faso', code: 'bf', code3: 'bfa',
  },
  {
    id: 108, name: 'Burundi', code: 'bi', code3: 'bdi',
  },
  {
    id: 116, name: 'Cambogia', code: 'kh', code3: 'khm',
  },
  {
    id: 120, name: 'Camerun', code: 'cm', code3: 'cmr',
  },
  {
    id: 124, name: 'Canada', code: 'ca', code3: 'can',
  },
  {
    id: 132, name: 'Capo Verde', code: 'cv', code3: 'cpv',
  },
  {
    id: 148, name: 'Ciad', code: 'td', code3: 'tcd',
  },
  {
    id: 152, name: 'Cile', code: 'cl', code3: 'chl',
  },
  {
    id: 156, name: 'Cina', code: 'cn', code3: 'chn',
  },
  {
    id: 196, name: 'Cipro', code: 'cy', code3: 'cyp',
  },
  {
    id: 170, name: 'Colombia', code: 'co', code3: 'col',
  },
  {
    id: 174, name: 'Comore', code: 'km', code3: 'com',
  },
  {
    id: 408, name: 'Corea del Nord', code: 'kp', code3: 'prk',
  },
  {
    id: 410, name: 'Corea del Sud', code: 'kr', code3: 'kor',
  },
  {
    id: 384, name: "Costa d'Avorio", code: 'ci', code3: 'civ',
  },
  {
    id: 188, name: 'Costa Rica', code: 'cr', code3: 'cri',
  },
  {
    id: 191, name: 'Croazia', code: 'hr', code3: 'hrv',
  },
  {
    id: 192, name: 'Cuba', code: 'cu', code3: 'cub',
  },
  {
    id: 208, name: 'Danimarca', code: 'dk', code3: 'dnk',
  },
  {
    id: 212, name: 'Dominica', code: 'dm', code3: 'dma',
  },
  {
    id: 218, name: 'Ecuador', code: 'ec', code3: 'ecu',
  },
  {
    id: 818, name: 'Egitto', code: 'eg', code3: 'egy',
  },
  {
    id: 222, name: 'El Salvador', code: 'sv', code3: 'slv',
  },
  {
    id: 784, name: 'Emirati Arabi Uniti', code: 'ae', code3: 'are',
  },
  {
    id: 232, name: 'Eritrea', code: 'er', code3: 'eri',
  },
  {
    id: 233, name: 'Estonia', code: 'ee', code3: 'est',
  },
  {
    id: 231, name: 'Etiopia', code: 'et', code3: 'eth',
  },
  {
    id: 242, name: 'Figi', code: 'fj', code3: 'fji',
  },
  {
    id: 608, name: 'Filippine', code: 'ph', code3: 'phl',
  },
  {
    id: 246, name: 'Finlandia', code: 'fi', code3: 'fin',
  },
  {
    id: 250, name: 'Francia', code: 'fr', code3: 'fra',
  },
  {
    id: 266, name: 'Gabon', code: 'ga', code3: 'gab',
  },
  {
    id: 270, name: 'Gambia', code: 'gm', code3: 'gmb',
  },
  {
    id: 268, name: 'Georgia', code: 'ge', code3: 'geo',
  },
  {
    id: 276, name: 'Germania', code: 'de', code3: 'deu',
  },
  {
    id: 288, name: 'Ghana', code: 'gh', code3: 'gha',
  },
  {
    id: 388, name: 'Giamaica', code: 'jm', code3: 'jam',
  },
  {
    id: 392, name: 'Giappone', code: 'jp', code3: 'jpn',
  },
  {
    id: 262, name: 'Gibuti', code: 'dj', code3: 'dji',
  },
  {
    id: 400, name: 'Giordania', code: 'jo', code3: 'jor',
  },
  {
    id: 300, name: 'Grecia', code: 'gr', code3: 'grc',
  },
  {
    id: 308, name: 'Grenada', code: 'gd', code3: 'grd',
  },
  {
    id: 320, name: 'Guatemala', code: 'gt', code3: 'gtm',
  },
  {
    id: 324, name: 'Guinea', code: 'gn', code3: 'gin',
  },
  {
    id: 624, name: 'Guinea-Bissau', code: 'gw', code3: 'gnb',
  },
  {
    id: 226, name: 'Guinea Equatoriale', code: 'gq', code3: 'gnq',
  },
  {
    id: 328, name: 'Guyana', code: 'gy', code3: 'guy',
  },
  {
    id: 332, name: 'Haiti', code: 'ht', code3: 'hti',
  },
  {
    id: 340, name: 'Honduras', code: 'hn', code3: 'hnd',
  },
  {
    id: 356, name: 'India', code: 'in', code3: 'ind',
  },
  {
    id: 360, name: 'Indonesia', code: 'id', code3: 'idn',
  },
  {
    id: 364, name: 'Iran', code: 'ir', code3: 'irn',
  },
  {
    id: 368, name: 'Iraq', code: 'iq', code3: 'irq',
  },
  {
    id: 372, name: 'Irlanda', code: 'ie', code3: 'irl',
  },
  {
    id: 352, name: 'Islanda', code: 'is', code3: 'isl',
  },
  {
    id: 584, name: 'Isole Marshall', code: 'mh', code3: 'mhl',
  },
  {
    id: 90, name: 'Isole Salomone', code: 'sb', code3: 'slb',
  },
  {
    id: 376, name: 'Israele', code: 'il', code3: 'isr',
  },
  {
    id: 380, name: 'Italia', code: 'it', code3: 'ita',
  },
  {
    id: 398, name: 'Kazakistan', code: 'kz', code3: 'kaz',
  },
  {
    id: 404, name: 'Kenya', code: 'ke', code3: 'ken',
  },
  {
    id: 417, name: 'Kirghizistan', code: 'kg', code3: 'kgz',
  },
  {
    id: 296, name: 'Kiribati', code: 'ki', code3: 'kir',
  },
  {
    id: 414, name: 'Kuwait', code: 'kw', code3: 'kwt',
  },
  {
    id: 418, name: 'Laos', code: 'la', code3: 'lao',
  },
  {
    id: 426, name: 'Lesotho', code: 'ls', code3: 'lso',
  },
  {
    id: 428, name: 'Lettonia', code: 'lv', code3: 'lva',
  },
  {
    id: 422, name: 'Libano', code: 'lb', code3: 'lbn',
  },
  {
    id: 430, name: 'Liberia', code: 'lr', code3: 'lbr',
  },
  {
    id: 434, name: 'Libia', code: 'ly', code3: 'lby',
  },
  {
    id: 438, name: 'Liechtenstein', code: 'li', code3: 'lie',
  },
  {
    id: 440, name: 'Lituania', code: 'lt', code3: 'ltu',
  },
  {
    id: 442, name: 'Lussemburgo', code: 'lu', code3: 'lux',
  },
  {
    id: 807, name: 'Macedonia del Nord', code: 'mk', code3: 'mkd',
  },
  {
    id: 450, name: 'Madagascar', code: 'mg', code3: 'mdg',
  },
  {
    id: 454, name: 'Malawi', code: 'mw', code3: 'mwi',
  },
  {
    id: 458, name: 'Malaysia', code: 'my', code3: 'mys',
  },
  {
    id: 462, name: 'Maldive', code: 'mv', code3: 'mdv',
  },
  {
    id: 466, name: 'Mali', code: 'ml', code3: 'mli',
  },
  {
    id: 470, name: 'Malta', code: 'mt', code3: 'mlt',
  },
  {
    id: 504, name: 'Marocco', code: 'ma', code3: 'mar',
  },
  {
    id: 478, name: 'Mauritania', code: 'mr', code3: 'mrt',
  },
  {
    id: 480, name: 'Mauritius', code: 'mu', code3: 'mus',
  },
  {
    id: 484, name: 'Messico', code: 'mx', code3: 'mex',
  },
  {
    id: 583, name: 'Micronesia', code: 'fm', code3: 'fsm',
  },
  {
    id: 498, name: 'Moldavia', code: 'md', code3: 'mda',
  },
  {
    id: 496, name: 'Mongolia', code: 'mn', code3: 'mng',
  },
  {
    id: 499, name: 'Montenegro', code: 'me', code3: 'mne',
  },
  {
    id: 508, name: 'Mozambico', code: 'mz', code3: 'moz',
  },
  {
    id: 516, name: 'Namibia', code: 'na', code3: 'nam',
  },
  {
    id: 520, name: 'Nauru', code: 'nr', code3: 'nru',
  },
  {
    id: 524, name: 'Nepal', code: 'np', code3: 'npl',
  },
  {
    id: 558, name: 'Nicaragua', code: 'ni', code3: 'nic',
  },
  {
    id: 562, name: 'Niger', code: 'ne', code3: 'ner',
  },
  {
    id: 566, name: 'Nigeria', code: 'ng', code3: 'nga',
  },
  {
    id: 578, name: 'Norvegia', code: 'no', code3: 'nor',
  },
  {
    id: 554, name: 'Nuova Zelanda', code: 'nz', code3: 'nzl',
  },
  {
    id: 512, name: 'Oman', code: 'om', code3: 'omn',
  },
  {
    id: 528, name: 'Paesi Bassi', code: 'nl', code3: 'nld',
  },
  {
    id: 586, name: 'Pakistan', code: 'pk', code3: 'pak',
  },
  {
    id: 585, name: 'Palau', code: 'pw', code3: 'plw',
  },
  {
    id: 591, name: 'Panama', code: 'pa', code3: 'pan',
  },
  {
    id: 598, name: 'Papua Nuova Guinea', code: 'pg', code3: 'png',
  },
  {
    id: 600, name: 'Paraguay', code: 'py', code3: 'pry',
  },
  {
    id: 604, name: 'Perù', code: 'pe', code3: 'per',
  },
  {
    id: 616, name: 'Polonia', code: 'pl', code3: 'pol',
  },
  {
    id: 620, name: 'Portogallo', code: 'pt', code3: 'prt',
  },
  {
    id: 492, name: 'Monaco', code: 'mc', code3: 'mco',
  },
  {
    id: 634, name: 'Qatar', code: 'qa', code3: 'qat',
  },
  {
    id: 826, name: 'Regno Unito', code: 'gb', code3: 'gbr',
  },
  {
    id: 180, name: 'RD del Congo', code: 'cd', code3: 'cod',
  },
  {
    id: 203, name: 'Rep. Ceca', code: 'cz', code3: 'cze',
  },
  {
    id: 140, name: 'Rep. Centrafricana', code: 'cf', code3: 'caf',
  },
  {
    id: 178, name: 'Rep. del Congo', code: 'cg', code3: 'cog',
  },
  {
    id: 214, name: 'Rep. Dominicana', code: 'do', code3: 'dom',
  },
  {
    id: 642, name: 'Romania', code: 'ro', code3: 'rou',
  },
  {
    id: 646, name: 'Ruanda', code: 'rw', code3: 'rwa',
  },
  {
    id: 643, name: 'Russia', code: 'ru', code3: 'rus',
  },
  {
    id: 659, name: 'Saint Kitts e Nevis', code: 'kn', code3: 'kna',
  },
  {
    id: 662, name: 'Saint Lucia', code: 'lc', code3: 'lca',
  },
  {
    id: 670, name: 'Saint Vincent e Grenadine', code: 'vc', code3: 'vct',
  },
  {
    id: 882, name: 'Samoa', code: 'ws', code3: 'wsm',
  },
  {
    id: 674, name: 'San Marino', code: 'sm', code3: 'smr',
  },
  {
    id: 678, name: 'São Tomé e Príncipe', code: 'st', code3: 'stp',
  },
  {
    id: 686, name: 'Senegal', code: 'sn', code3: 'sen',
  },
  {
    id: 688, name: 'Serbia', code: 'rs', code3: 'srb',
  },
  {
    id: 690, name: 'Seychelles', code: 'sc', code3: 'syc',
  },
  {
    id: 694, name: 'Sierra Leone', code: 'sl', code3: 'sle',
  },
  {
    id: 702, name: 'Singapore', code: 'sg', code3: 'sgp',
  },
  {
    id: 760, name: 'Siria', code: 'sy', code3: 'syr',
  },
  {
    id: 703, name: 'Slovacchia', code: 'sk', code3: 'svk',
  },
  {
    id: 705, name: 'Slovenia', code: 'si', code3: 'svn',
  },
  {
    id: 706, name: 'Somalia', code: 'so', code3: 'som',
  },
  {
    id: 724, name: 'Spagna', code: 'es', code3: 'esp',
  },
  {
    id: 144, name: 'Sri Lanka', code: 'lk', code3: 'lka',
  },
  {
    id: 840, name: 'Stati Uniti', code: 'us', code3: 'usa',
  },
  {
    id: 710, name: 'Sudafrica', code: 'za', code3: 'zaf',
  },
  {
    id: 729, name: 'Sudan', code: 'sd', code3: 'sdn',
  },
  {
    id: 728, name: 'Sudan del Sud', code: 'ss', code3: 'ssd',
  },
  {
    id: 740, name: 'Suriname', code: 'sr', code3: 'sur',
  },
  {
    id: 752, name: 'Svezia', code: 'se', code3: 'swe',
  },
  {
    id: 756, name: 'Svizzera', code: 'ch', code3: 'che',
  },
  {
    id: 748, name: 'Swaziland', code: 'sz', code3: 'swz',
  },
  {
    id: 762, name: 'Tagikistan', code: 'tj', code3: 'tjk',
  },
  {
    id: 158, name: 'Taiwan', code: 'tw', code3: 'twn',
  },
  {
    id: 834, name: 'Tanzania', code: 'tz', code3: 'tza',
  },
  {
    id: 764, name: 'Thailandia', code: 'th', code3: 'tha',
  },
  {
    id: 626, name: 'Timor Est', code: 'tl', code3: 'tls',
  },
  {
    id: 768, name: 'Togo', code: 'tg', code3: 'tgo',
  },
  {
    id: 776, name: 'Tonga', code: 'to', code3: 'ton',
  },
  {
    id: 780, name: 'Trinidad e Tobago', code: 'tt', code3: 'tto',
  },
  {
    id: 788, name: 'Tunisia', code: 'tn', code3: 'tun',
  },
  {
    id: 792, name: 'Turchia', code: 'tr', code3: 'tur',
  },
  {
    id: 795, name: 'Turkmenistan', code: 'tm', code3: 'tkm',
  },
  {
    id: 798, name: 'Tuvalu', code: 'tv', code3: 'tuv',
  },
  {
    id: 804, name: 'Ucraina', code: 'ua', code3: 'ukr',
  },
  {
    id: 800, name: 'Uganda', code: 'ug', code3: 'uga',
  },
  {
    id: 348, name: 'Ungheria', code: 'hu', code3: 'hun',
  },
  {
    id: 858, name: 'Uruguay', code: 'uy', code3: 'ury',
  },
  {
    id: 860, name: 'Uzbekistan', code: 'uz', code3: 'uzb',
  },
  {
    id: 548, name: 'Vanuatu', code: 'vu', code3: 'vut',
  },
  {
    id: 862, name: 'Venezuela', code: 've', code3: 'ven',
  },
  {
    id: 704, name: 'Vietnam', code: 'vn', code3: 'vnm',
  },
  {
    id: 887, name: 'Yemen', code: 'ye', code3: 'yem',
  },
  {
    id: 894, name: 'Zambia', code: 'zm', code3: 'zmb',
  },
  {
    id: 716, name: 'Zimbabwe', code: 'zw', code3: 'zwe',
  }],

};
